<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row>
                <b-col cols="12">
                    <ValidationProvider name="semester_id" rules="required" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('semester')">
                            <semesters-selectbox v-model="formData.semester_id"
                                                 :validateError="errors[0]"
                            />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12">
                    <b-form-group :label="$t('type')">
                        <multi-selectbox :multiple="false" v-model="courseType" :options="[{text: $t('this_university_course'), value: 1}, {text: $t('external_course'), value: 2}]"/>
                    </b-form-group>
                </b-col>
                <b-col cols="12" v-if="courseType==1">
                    <ValidationProvider name="course_id" rules="required" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('course')">
                            <course-auto-complete v-model="formData.course_id"
                                                  v-on:courseSelected="courseSelected"
                                                  :valueObject="courseObject"
                                                  :validateError="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" v-if="courseType==2">
                    <country-and-university-selectbox v-model="formData.university_id"/>
                </b-col>
                <b-col cols="12" v-if="courseType==2">
                    <ValidationProvider name="university_course_id" rules="required" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('course')">
                            <university-course-auto-complete v-model="formData.university_course_id"
                                                             :university-id="formData.university_id"
                                                  v-on:courseSelected="universityCourseSelected"
                                                  :valueObject="courseObject"
                                                  :validateError="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="6">
                    <ValidationProvider name="credit" rules="required" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('credit')">
                            <b-form-input v-model="formData.credit"
                                          type="number"
                                          step="1"
                                          :state="errors[0] ? false : null"
                            />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="6">
                    <ValidationProvider name="grading_system_id" rules="" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('grading_system')">
                            <grade-system-selectbox :grading_system_id="formData.grading_system_id"
                                                    v-model="formData.grading_system_id"
                                                    :validateError="errors[0]"/>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="6">
                    <ValidationProvider name="letter_grade" rules="" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('letter_grade')">
                            <grade-selectbox :grading_system_id="formData.grading_system_id"
                                             value-type="grade"
                                             v-model="formData.letter_grade"
                                             :validateError="errors[0]"/>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12">
                    <ValidationProvider name="type" rules="" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('course_type')">
                            <parameter-selectbox code="student_course_types"
                                                 v-model="formData.type"
                                                 :validateError="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12">
                    <ValidationProvider name="section_id" rules="" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('section')">
                            <multi-selectbox :options="tSections"
                                             :multiple="false"
                                                 v-model="formData.section_id"
                                                 :validateError="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" v-if="pSections.length>0">
                    <ValidationProvider name="lab_section_id" rules="" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('lab_section')">
                            <multi-selectbox :options="pSections"
                                             :multiple="false"
                                             v-model="formData.lab_section_id"
                                             :validateError="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
            </b-row>
            <div class="d-flex justify-content-center mt-2">
                <b-button variant="primary"
                          @click="createForm"/>
            </div>
        </ValidationObserver>
    </div>
</template>

<script>
    // Components
    import CourseAutoComplete from '@/components/interactive-fields/CourseAutoComplete';
    import GradeSelectbox from '@/components/interactive-fields/GradeSelectbox';
    import ParameterSelectbox from '@/components/interactive-fields/ParameterSelectbox';
    import SemestersSelectbox from '@/components/interactive-fields/SemestersSelectbox';

    // Services
    import StudentSlotService from '@/services/StudentSlotService'
    import StudentCourseService from '@/services/StudentCourseService'

    import SectionService from '@/services/SectionService'
    // Other
    import {ValidationObserver, ValidationProvider} from 'vee-validate'
    import qs from "qs";
    import MultiSelectbox from "@/components/interactive-fields/MultiSelectbox";
    import UniversityCourseAutoComplete
        from "@/components/interactive-fields/UniversityCourseAutoComplete";
    import CountryAndUniversitySelectbox from "@/components/interactive-fields/CountryAndUniversitySelectbox";
    import GradeSystemSelectbox from "@/components/interactive-fields/GradeSystemSelectbox";

    export default {
        components: {
            GradeSystemSelectbox,
            CountryAndUniversitySelectbox,
            UniversityCourseAutoComplete,
            MultiSelectbox,
            CourseAutoComplete,
            GradeSelectbox,
            ParameterSelectbox,
            SemestersSelectbox,

            ValidationProvider,
            ValidationObserver
        },
        props: {
            slotId: {
                type: Number
            },
            studentProgramId: {
                type: Number
            }
        },
        data() {
            return {
                slot: [],

                courseObject: {
                    value: null,
                    text: null
                },
                formData: {
                    student_slot_id: null,
                    student_program_id: null,
                    semester_id: null,
                    course_id: null,
                    credit: null,
                    grading_system_id: null,
                    letter_grade: null,
                    type: null
                },
                formLoading: false,
                tSections:[],
                pSections:[],
                courseType:1
            }
        },
        created() {
            this.getSlot()
        },
        mounted() {
            this.$refs.formModalValidate.reset()
        },
        methods: {
            async getSlot() {
                await StudentSlotService.get(this.slotId)
                                        .then(response => {
                                            this.slot = response.data.data;
                                            if (this.slot.course_id && this.slot.course.grading_system_id) {
                                                this.formData.grading_system_id = this.slot.course.grading_system_id
                                            }
                                        })
                                        .catch(e => {
                                            this.showErrors(e)
                                        })
            },

            async createForm() {
                const isValid = await this.$refs.formModalValidate.validate();
                if (isValid) {
                    this.formLoading = true;
                    this.formData.student_slot_id = this.slotId
                    this.formData.student_program_id = this.studentProgramId
                    StudentCourseService.store(this.formData)
                                        .then(response => {
                                            this.$emit('createFormSuccess', true);
                                            this.$toast.success(this.$t('api.' + response.data.message));
                                        })
                                        .catch(e => {
                                            this.showErrors(e, this.$refs.formModalValidate);
                                        })
                                        .finally(() => {
                                            this.formLoading = false
                                        })
                }
            },
            courseSelected(course) {
                this.formData.university_course_id = null;
                this.formData.course_id = course.item.id;
                this.formData.credit = course.item.credit;
                this.formData.grading_system_id = course.item.grading_system_id;
                this.getSections();
            },

            universityCourseSelected(course) {
                this.formData.course_id = null;
                this.formData.section_id = null;
                this.formData.lab_section_id = null;
                this.tSections=[];
                this.pSections=[];
                this.formData.university_course_id = course.item.id;
                this.formData.credit = course.item.credit;
                this.formData.grading_system_id = null;
            },

            getSections(){
                const config = {
                    params: {
                        filter: {
                            semester_id: this.formData.semester_id,
                            course_id: this.formData.course_id,
                        },
                        limit: -1,
                        sort: 'section'
                    },
                    paramsSerializer: (params) => qs.stringify(params, {encode: false}),
                };
                SectionService.options(config).then(response=>{
                    this.tSections = response.data.data.filter(function (item){
                        return item.type==1;
                    }).map(function (item){
                        return {value: item.id, text: item.section};
                    });

                    this.pSections = response.data.data.filter(function (item){
                        return item.type==2;
                    }).map(function (item){
                        return {value: item.id, text: item.section};
                    });
                });
            }
        }
    }
</script>

